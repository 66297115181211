.custom-checkbox .form-check-input {
  border: 1px solid gray;
}

/* Card header for heading, search, and edit mode toggle */
.card-header {
  background-color: #eee;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  padding: 15px;
}

.data-table .rdt_TableHeadRow {
  background-color: #f8f9fa;
  font-weight: bold;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.data-table .pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-table .pagination button {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 16px;
}

.data-table .pagination button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.data-table .rdt_TableCell,
.rdt_TableHeadRow {
  font-size: 16px !important;
}
