/* Main container */
.main-container {
  display: flex;
  height: 100vh;
}

/* Fixed Sidebar */
.sidebar {
  width: 200px;
  background-color: #1c1050;
  padding: 20px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  display: flex;
  flex-direction: column;
}

/* Content Area */
.content {
  margin-left: 200px;
  /* Space for fixed sidebar */
  padding: 20px;
  width: calc(100% - 200px);
  /* background-color: #f5f5f5; */
  height: 100vh;
  overflow-y: auto;
}

/* Active NavLink styling */
.active {
  background-color: #3a206a;
  border-radius: 8px;
  padding: 5px 0 10px 0;
}

button {
  width: 100%;
  text-align: left;
}
